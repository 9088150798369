<template>
    <VueDatePicker
            v-model="date"
            format="YYYY-MM-DD"
    />
</template>
<script>
    import { VueDatePicker } from '@mathieustan/vue-datepicker';
    import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

    export default  {
        components : {
            VueDatePicker,
        },
        data: () => ({
            date: new Date(),
        }),
    };
</script>